import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { distanceInWordsToNow } from 'date-fns';
import { Flipped } from 'react-flip-toolkit';
import { theme } from '../theme';
import { AUTHORS } from './Authors';
import { LinkArea, LinkAreaRenderProps } from './LinkArea';
import { AnimateAfterFlip } from './Animations/AnimateAfterFlip';
import { Label } from './Inputs/Label';
import { Heading } from './Text/Heading';
import { Text } from './Text/Text';
import { __BROWSER__ } from '../utils/env';

export interface BlogCardProps {
  post: GatsbySchema.MarkdownRemark;
  featured?: boolean;
}

export interface BlogCardState {
  flipping?: boolean;
}

export class BlogCard extends React.Component<BlogCardProps, BlogCardState> {
  state: BlogCardState = {};

  handleStart = (el: HTMLDivElement) => {
    el.style.zIndex = '5';
    this.setState({ flipping: true });
  };

  handleComplete = (el: HTMLDivElement) => {
    el.style.zIndex = '';
    this.setState({ flipping: false });
  };

  render() {
    const {
      post: {
        frontmatter: {
          slug,
          image,
          category,
          title,
          description,
          author,
          date,
        },
      },
      featured,
    } = this.props;
    const { flipping } = this.state;

    return (
      <LinkArea
        key={slug}
        css={{
          width: '100%',
          position: 'relative',
          padding: '1rem',
          [theme.media.medium]: {
            width: '50%',
          },
          [theme.media.large]: {
            width: featured ? '50%' : '33.33333%',
          },
        }}
      >
        {({ link }: LinkAreaRenderProps) => (
          <div
            css={{
              height: '100%',
              transition: 'transform 200ms ease-out',
              [theme.media.medium]: {
                '&:hover': {
                  transform: `translateY(-.5rem)`,
                },
              },
            }}
          >
            <Flipped
              flipId={`${slug}-card`}
              onStart={this.handleStart}
              onComplete={this.handleComplete}
            >
              <div
                css={{
                  height: '100%',
                  position: 'relative',
                  background: theme.color.white,
                  cursor: 'pointer',
                  boxShadow: `${theme.shadow.medium}, ${theme.shadow.large}`,
                }}
              >
                <Flipped inverseFlipId={`${slug}-card`}>
                  <div
                    css={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                    }}
                  >
                    {image && (
                      <Flipped flipId={`${slug}-photo`}>
                        <div>
                          <Img sizes={image.childImageSharp.sizes} />
                        </div>
                      </Flipped>
                    )}

                    <div
                      css={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        padding: '2rem',
                      }}
                    >
                      <AnimateAfterFlip
                        flipping={flipping}
                        from={{ opacity: 0 }}
                        to={{ opacity: 1 }}
                      >
                        <div
                          css={{
                            display: 'flex',
                            marginBottom: '1rem',
                          }}
                        >
                          <Label
                            component="div"
                            css={{
                              width: '100%',
                              color: theme.color.purple,
                            }}
                          >
                            {category}
                          </Label>

                          <Label
                            component="div"
                            css={{
                              color: theme.color.grayDarker,
                              marginLeft: 'auto',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {__BROWSER__ &&
                              distanceInWordsToNow(date, {
                                addSuffix: true,
                              })}
                          </Label>
                        </div>
                      </AnimateAfterFlip>

                      <div
                        css={{
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          flexGrow: 1,
                          justifyContent: 'space-between',
                        }}
                      >
                        <div>
                          <Flipped flipId={`${slug}-title`}>
                            <Heading
                              size={2}
                              css={{
                                marginBottom: '1rem',
                              }}
                            >
                              <Flipped inverseFlipId={`${slug}-title`} scale>
                                <div>
                                  <Link
                                    to={`/blog/${slug}`}
                                    state={{ overlay: true }}
                                    innerRef={link}
                                  >
                                    {title}
                                  </Link>
                                </div>
                              </Flipped>
                            </Heading>
                          </Flipped>

                          <AnimateAfterFlip
                            flipping={flipping}
                            from={{ opacity: 0 }}
                            to={{ opacity: 1 }}
                          >
                            <Text
                              css={{
                                color: theme.color.gray,
                                marginBottom: '1rem',
                                [theme.media.medium]: {
                                  display: featured ? 'block' : 'none',
                                },
                              }}
                            >
                              {description}
                            </Text>
                          </AnimateAfterFlip>
                        </div>
                        <Flipped flipId={`${slug}-author`}>
                          <div>
                            <Flipped inverseFlipId={`${slug}-author`} scale>
                              <div
                                css={{
                                  bottom: 0,
                                }}
                              >
                                {AUTHORS[author]}
                              </div>
                            </Flipped>
                          </div>
                        </Flipped>
                      </div>
                    </div>
                  </div>
                </Flipped>
              </div>
            </Flipped>
          </div>
        )}
      </LinkArea>
    );
  }
}
