import React from 'react';
import { graphql } from 'gatsby';
import { Layout } from '../layouts';
import { BlogCard } from '../components/BlogCard';
import { JobCard } from '../components/JobCard';
import { Container } from '../components/Container';
import { Footer } from '../components/Footer';
import { AccentHeading } from '../components/Text/AccentHeading';
import { Heading } from '../components/Text/Heading';
import { Head } from '../components/Head';

export interface BlogProps {
  data: GatsbySchema.Query;
}

export default class Blog extends React.Component<BlogProps> {
  render() {
    const {
      data: {
        allMarkdownRemark: { edges: posts },
      },
    } = this.props;

    return (
      <Layout>
        <Head
          title="Blog"
          description="Blog Posts and Essays on Technology, Business Strategy, Design, and Engineering."
        />
        <Container padding={['all']}>
          <div
            css={{
              marginBottom: '6rem',
            }}
          >
            <AccentHeading>Blog</AccentHeading>
            <Heading component="h1">What We're Saying</Heading>
          </div>
          <div
            css={{
              display: 'flex',
              flexWrap: 'wrap',
              margin: '-1rem',
            }}
          >
            {posts.map(({ node: post }, index) => {
              return (
                <BlogCard
                  key={post.frontmatter.slug}
                  post={post}
                  featured={index < 2}
                />
              );
            })}
          </div>
        </Container>
        <div css={{ marginTop: 'auto' }}>
          <Footer />
        </div>
      </Layout>
    );
  }
}

export const query = graphql`
  query Blog {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: { glob: "**/blog/**" }
        frontmatter: { publish: { ne: false } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 1000
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            date
            image {
              childImageSharp {
                sizes(maxWidth: 700, maxHeight: 467) {
                  ...GatsbyImageSharpSizes_noBase64
                }
              }
            }
            category
            description
            author
          }
          headings {
            depth
            value
          }
        }
      }
    }
  }
`;
